@import url('https://fonts.googleapis.com/css2?family=Barlow+Condensed:ital,wght@0,400;0,500;0,600;0,700;0,800;1,400&family=Montserrat&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Borel&family=EB+Garamond&family=Inter:wght@300;400;500;600;700&family=Lobster+Two:wght@400;700&family=Lora:wght@700&family=Medula+One&family=Montserrat:ital,wght@0,400;0,500;0,600;0,700;0,800;0,900;1,400&family=Oswald:wght@200;300;400;500;600;700&family=Pacifico&family=Poppins:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,400;1,500;1,600;1,700;1,800&family=Rochester&family=Vollkorn:wght@400;500;600;700&display=swap');

* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

body {
  font-family: 'Montserrat', sans-serif;
  color: #3d3d3d;
}

a {
  text-decoration: none;
  color: #3d3d3d;
}

p {
  font-size: 1rem;
}

ul {
  list-style: none;
}

img {
  width: 100%;
}

.section-title {
  font-family:Georgia, 'Times New Roman', Times, serif;
  font-size: 2rem;
  text-transform: uppercase;
  line-height: 1;
  font-weight: 600;
}

.section-container {
  padding: 70px 90px;
}






@media screen and (max-width: 993px) {

  .section-container {
    padding-left: 4vw;
    padding-right: 4vw;
  }
  
}


@media screen and (max-width: 768px) {
  .section-title {
    font-family:Georgia, 'Times New Roman', Times, serif;
    font-size: 1.3rem;
  }

  .section-container {
    padding-left: 4vw;
    padding-right: 4vw;
  }
  
}
