.news-page-container {
    height: 150vh;
    background-color: #dddddd;
    padding: 25% 30px;
    text-align: center;
}

.news-page-container h1 {
    font-size: 5rem;
}

.event-details {
    margin-left: 5px;
}

.news-title {
    margin: 20px 0;
}

.news-content {
    margin-top: 20px;
}