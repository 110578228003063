.services-section {
    display: flex;
    flex-direction: column;
    padding-top: 0;
  }
  
  .services-header {
    margin-bottom: 30px;
  }
  
  .services-list {
    display: flex;
    flex-wrap: wrap;
    gap: 2%;
  }
  
  .service-card {
    width: 48%;
    margin-bottom: 20px;
    padding: 20px;
    background-color: #eef3ec;
  }
  
  .service-title {
    margin-bottom: 20px;
  }
  
  @media screen and (max-width: 992px) {
    .services-section {
      padding-left: 4vw;
      padding-right: 4vw;
    }
  }
  
  @media screen and (max-width: 768px) {
    .services-section {
      padding-left: 4vw;
      padding-right: 4vw;
    }
  
    .service-card {
      width: 100%;
      margin: 0 0 10px 0;
    }
  }
  