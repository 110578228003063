.slider-container {
    padding: 80px 0px;
  }
  
  .slider {
    margin: 0 auto;
    width: 100%;
  }
  
  .slider-container h2 {
    color: #1f3f32/*1f3f32*/;
    font-size: 4rem;
    font-weight: 900;
    line-height: 0.9;
    margin-bottom: 30px;
    padding: 0px 90px;
    text-transform: uppercase;
    width: 85%;
  }
  
  .slider-container p {
    color: #2E6A4F;
    font-size: 1.1rem;
    margin-bottom: 40px;
    padding: 0px 90px;
    width: 80%;
  }
  
  .slider-container span {
    color: #1f3f32;
    font-weight: bold;
  }
  
  .slick-next {
    width: 0 !important;
  }
  
  .slick-prev:before,
  .slick-next:before {
    display:none;
    position: relative;
  }
  
  ul.slick-dots {
    width: 50%;
    right: 60px;
    text-align: right;
  }
  
  .slick-dots li {
    color: #2E6A4F;
    margin: 0 5px; /* Adjust the spacing between dots as needed */
  }
  
  .slick-dots li.slick-active button {
    border: 1px solid #2E6A4F; /* Change the active dot color */
    border-radius: 50%;
  }
  
  .slick-dots li button::before {
    color: #2E6A4F !important;;
  }
  
  
  .hero-section-btns {
    display: flex;
    align-items: flex-start;
    padding: 0px 0px 0px 90px;
    z-index: 1;
    width: 65%;
  }
  
  .start-application-link {
    background-color: #1f3f32;
    padding: 15px 10px;
    font-size: 1.1rem;
    line-height: 1;
    color: #fff;
    margin-right: 10px;
    text-align: center;
    width:100%;
    border-radius: 10px;
    transition: 0.3s;
  }
  
  .apply-support-link {
    width: 100%;
  }
  
  .start-application-link:hover {
    opacity: 0.8;
  }
  
  .support-us {
    background-color: #2E6A4F;
    transition: 0.3s;
  }
  
  @media (max-width: 1200px) {
    .slider-container h2 {
        font-size: 3rem;
        width: 85%;
    }
    
    .slider-container p {
      width: 85%;
    }

    .hero-section-btns {
      width: 75%;
    }
  }
  
  @media (max-width: 992px) {
    .slider-container {
      padding: 180px 0 90px;
      background-size: cover;
      background-position: center;
      border-bottom: 3px solid #2E6A4F;
    }
    
  
    .slider-container h2 {
      line-height: 0.9;
      margin-bottom: 30px;
      padding-left: 4vw;
      
    }
    
    .slider-container p {
      font-size: 0.9rem;
      margin-bottom: 50px;
      padding-left: 4vw;
      padding-right: 23px;
      width: 85%;
    }
  
    .hero-section-btns {
      padding-left: 4vw;
      width: 80%;
    }
    
    .start-application-link {
      font-size: 1rem;
    }
    
    .apply-support-link {
      width: 100%;
    }
  
  }
  
  @media (max-width: 768px) {
    .slider-container {
      padding: 200px 0 120px;
      background-size: cover;
      background-position: center;
    }
  
    .slider-container h2 {
      width: 100%;
      padding: 0 4vw 0 4vw;
    }
    
    .slider-container p {
      width: 100%;
    }
  
    .slick-dots {
      display: none !important;
    }
  
    .arrow-icon {
      font-size: 20px;
    }
  
    .hero-section-btns {
      flex-direction: column;
      align-items: flex-start;
      width: 100%;
      padding-left: 4vw;
      padding-right: 4vw;
    }
    
    .start-application-link {
      font-size: 1rem;
      width:100%;
      margin-bottom: 10px;
      margin-right: 10px;
    }
  
    .apply-support-link {
      width: 100%;
    }
  }


  @media (max-width: 992px) {
    .slider-container h2 {
        font-size: 2.5rem;
      }
  }

  
  @media (max-width: 576px ) {
  
    .slider-container {
      padding: 150px 0 80px;
      background-size: cover;
      background-position: center;
    }

    .slider-container h2 {
        font-size: 2rem;
      }
  
    .hero-section-btns h3 {
      font-size:medium;
    }
  }
  
  @media (max-width: 520px) {
    .start-application-link {
      margin-right: 0px;
    }
  }

  @media (max-width: 457px) {
    .slider-container h2 {
        font-size: 1.8rem;
      }
  }

  
  
  @media (max-width: 300px) {
    .slider-container h2 {
      width: 100%;
      padding: 0;
      padding-left: 4vw;
      padding-right: 4vw;
    }
    
    .slider-container p {
      width: 100%;
    }
  }
   
    