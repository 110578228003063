.header-container {
    padding: 50px 0;
}

.page-header {
    padding-top: 0;
    padding-bottom: 0;
}

.header-title {
    font-size: 3rem;
    font-weight: 700;
    text-transform: uppercase;
}


@media (max-width: 1200px) {
    .header-title {
        font-size: 2.5rem;
    }
}


@media (max-width: 992px) {
    .header-title {
        font-size: 2rem;
    }

    .header-container {
        padding: 120px 0 50px;
    }
}

@media (max-width: 520px) {
    .header-title {
        font-size: 1.8rem;
    }
}