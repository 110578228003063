.certified-section-wrapper {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    padding: 50px 0;
}

.get-certified-text {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 50%;
}

.apply-checkstat-btns {
    display: flex;
    width: 80%;
}

.learn-more {
    width: 45%;
    
}

.pack-container {
    position: relative;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    margin-bottom: 15px;
    background-color: #ccc;
    height: inherit;
}


.application-process {
    display: flex;
}

.number-container {
    width: 40px;
    height: auto;
    display: flex;
    align-items: center;
    flex-direction: column;
}

.number {
    width: 40px;
    height: 40px;
    background-color: #2E6A4F;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #fff;
    font-size: 1rem;
    font-weight: 600;
    z-index: 1;
}

.process {
    width: 90%;
    padding: 5px 0 30px 40px;
    border-left: 1px solid #333;
    margin-left: -20px;
}

.process-title {
    font-size: 1.5rem;
    font-weight: 600;
    margin-bottom: 30px;
}

.last-process {
    border: 0
}

.process h3 {
    font-size: 1.3rem;
    margin-bottom: 10px;
}

.process-btns {
    width: 100%;
    margin-top: 30px;
}

.process-btns .check {
    margin-right: 0
}

@media screen and (max-width: 1108px) {

    .process-btns {
        width: 100%;
    }
}

@media screen and (max-width: 992px) {
    .apply-checkstat-btns {
        width: 85%;
    }

    .process-btns {
        width: 100%;
    }
}

@media screen and (max-width: 768px) {
    .certified-section-wrapper {
        flex-direction: column;
    } 

    .get-certified-text {
        width: 100%;
        margin-bottom: 30px;
    }

    .apply-checkstat-btns {
        width: 100%;
    }

    .learn-more {
        width: 100%;
        display: flex;
    }

    .pack-container {
        width: 100%;
        margin-bottom: 50px;
    }

    .process-title, .process h3 {
        font-size: 1.2rem;
    }

    
}


@media screen and (max-width: 520px) {

    .apply-checkstat-btns {
        flex-direction: column;
    }

    .learn-more {
        flex-direction: column;
    }

    .pack-container {
        width: 100%;
        margin-right: 0px;
    }

    .pack-download {
        width: 100%;
    }
}