.mission-vision-section-container {
    display: flex;
    padding: 100px 90px;
}

.mission-vision-btns-container {
    display: flex;
    justify-content: flex-end;
    width: 30%;
}

.mission-vision-btns {
    display: flex;
    flex-direction: column;
}

.mission-vision {
    padding: 0;
    border: 0;
    text-align: right;
    text-transform: uppercase;
    line-height: 1;
    font-family: Georgia, 'Times New Roman', Times, serif;
    font-weight: bolder;
    font-size: 2rem;
    margin-bottom: -5px;
    color: #ddd;
    cursor: default;
    transition: color 0.2s, background-color 0.2s;
}

.mission-vision-btns .active {
    color: #333;
    /* Add other styling as needed */
}

.mission-vision:not(.active):hover {
    cursor: pointer; /* Change cursor to pointer on hover for inactive buttons */
}

.statements:hover {
    cursor: none;
    color: initial
}

.ampercent {
    font-family: 'Times New Roman', Times, serif;
    font-weight: bolder;
    font-size: 12rem;
    line-height: 1;
    margin-top: -15px;
    margin-left: -50px;
    z-index: -1;
    color: #ccc;
}

.mission-vision-text {
    padding-left: 30px;
}

.mission-vision-text h2 {
    margin-bottom: 20px;
}

.mission {
    margin-bottom: 40px;
}

@media screen and (max-width: 993px) {
    .mission-vision-section-container {
        padding-left: 4vw;
        padding-right: 4vw;
    }

    .mission-vision-btns-container {
        width: 40%;
    }
}


@media screen and (max-width: 768px) {
    .mission-vision-section-container {
        flex-direction: column;
        padding-left: 4vw;
        padding-right: 4vw;
    }

    .mission-vision-btns-container {
        display: flex;
        justify-content: flex-start;
        width: 100%;
    }

    .mission-vision-btns {
        display: flex;
        flex-direction: column;
        margin-bottom: 30px;
    }

    .mission-vision {
        text-align: left;
        font-size: 1.5rem;
        margin-bottom: -5px;
        color: #ddd;
        cursor: default;
        transition: color 0.2s, background-color 0.2s;
    }

    .mission-vision-text {
        padding-left: 0px;
    }
}