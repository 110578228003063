.about-wrapper {
    display: flex;
    position: relative;
    justify-content: space-between;
}

main {
    width:65%;
    padding-right: 50px;
}

.main-sections {
    margin-bottom: 50px;
    padding-bottom: 50px;
    border-bottom: 1px solid rgba(51,51,51,0.2);
}

.governance {
    border: 0;
    padding: 0;
}

.main-sections h2, .main-sections h4 {
    margin-bottom: 20px;
}

.main-sections p {
    margin-bottom: 10px;
}

.main-sections ul li {
    display: flex;
    line-height: 1;
    padding-bottom: 10px;
}

.check {
    margin-right: 10px;
    width: 3%;
}

.list-text {
    width: 97%;
}

.values-section {
    margin-bottom: 0;
    padding-bottom: 0;
    border: 0;
}

.core-values {
    margin-bottom: 30px;
}

.values-last {
    margin: 0;
}

.core-values h3 {
    margin-bottom: 15px;
}

.my-component {
    width: 30%;
}



aside {
    width: 100%;
}

aside ul {
    background-color: #eef3ec;
}

aside ul h3 {
    margin-bottom: 10px;
    background-color: #1f3f32;
    color: #fff;
    padding: 8px 20px;
}

.about-links {
    padding-left: 0;
    padding: 10px 20px;
    display: flex;
    width: 300px;
    align-items: center;
    border-bottom: #ddd;
    border-bottom: 1px solid rgba(51,51,51,0.1);
}

.about-links:hover {
    color: #1f3f32;
}

aside ul li .active-about-link {
    color: #1f3f32;
    font-weight: 600;
    cursor: default;
}

.info-bar {
    background-color: #CFF4FC;
    padding: 10px;
    font-weight: 600;
    margin-top: 20px;
}


@media screen and (max-width: 992px) {
    .about-links {
        width: 250px;
    }
}

@media screen and (max-width: 768px) {
    .main-about {
        padding-top: 20px;
    }
    
    .about-wrapper {
        flex-direction: column;
        
    }

    main {
        width:100%;
        padding: 0;
        margin-top: 30px;
    }

    .main-sections {
        margin-bottom: 50px;
        padding-bottom: 50px;
        border-bottom: 1px solid rgba(51,51,51,0.2);
    }

    .about-links {
        width: 100%;
    }
}