.homepage {
    width: 100%;
    margin: 0;
    position: relative;
}


@media (max-width: 992px)  {

    .homepage-container {
        height:0;
        justify-content: center;
        padding-left: 30px;
        background-position: center;
    }

    .homepage-container h1 {
        padding-left: 15px;
        font-size:1.5rem;
        border-left: 2px solid;
    }
    
    .homepage-container p {
        padding-left: 0px;
        width: 100%;
        font-size: 0.9rem;
    }
}