.scroll-to-top-button {
    position: fixed;
    border: 0;
    width: 50px;
    height: 50px;
    border-radius: 50%;
    background-color: #1f3f32;
    color: #fff;
    bottom: 65px; /* Adjust the distance from the bottom as needed */
    right: 20px; /* Adjust the distance from the right as needed */
    /* Other styling properties like background color, font size, etc. */
  }

  .scroll-to-top-button:hover {
    cursor: pointer;
  }