.board-member-cards-container-main {
    flex-direction: column;
}

.board-member-card-main {
    width: 100%;
    display: flex;
    margin-bottom: 50px;
}

.board-member-card-main .board-member-img {
    width: 50%;
}



.board-member-card-main .board-member-details {
    width: 70%;
    padding-left: 20px;
}

.board-member-details-name {
    font-size: 2.5rem;
}

.board-member-card-main .board-member-details .country {
    margin-bottom: 20px;
    margin-top: 10px;
}

.board-member-description-list {
    padding-left: 16px;
}

.board-member-description-list li {
    list-style-type: disc;
}

.board-member-details-about h3 {
    margin-bottom: 20px;
}

.board-member-details-about p {
    margin-bottom: 15px;
}


.team-members-container {
    display: flex;
    gap: 20px;
    flex-wrap: wrap;
}

.team-member-card {
    width: 20%;
}



@media screen and (max-width: 768px) {


    .board-member-card-main .board-member-details {
        width: 100%;
        padding-left: 0px;
    }

    .board-member-card-main .board-member-img {
        width: 100%;
    }

    .board-member-details-name {
        font-size: 1.8rem;
    }

    .board-member-card-main .news-featured-img {
        width: 100%
      }

    .team-members-container {
        flex-direction: column;
    }

    .team-member-card {
        width: 100%;
        display: flex;
    }
}