.certified-section {
    background-color: #2E6A4F;
    padding-top: 70px;
    padding-bottom: 70px;
}

.certified-members-section {
    display: flex;
    color: #fff
}

.certifed-section-text {
    padding-right: 50px;
    width: 50%;
}

.certifed-section-text .section-title {
    margin-bottom: 20px;
}

.certifed-section-text p {
    margin-bottom: 30px;
}

.certified-members {
    display: flex;
    width: 50%;
    gap: 5%;
}

.certified-member-card {
    background-color: #fff;
    color: #333;
    width: 33%;
    padding: 20px;
}

.business-logo {
    height: 50px;
    width: 50px;
    border: 2px solid #333;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 10px;
}

.for-large-screen {
    display: block;
}

.for-small-screen {
    display: none;
}

.link-certified {
    color: #fff;
}


@media screen and (max-width: 768px)  {
    .certified-members-section {
        flex-direction: column;
    }

    .certifed-section-text {
        padding-right: 0px;
        width: 100%;
    }

    .certified-members {
        width: 100%;
        margin-bottom: 20px;
        flex-direction: column;
    }

    .certified-member-card {
        width: 100%;
        display: flex;
        align-items: center;
        margin-bottom: 10px;
    }

    .business-logo {
        margin-right: 15px;
        margin-bottom: 0;
    }

    .for-small-screen {
        display: block;
    }

    .for-large-screen {
        display: none;
    }
}

@media screen and (max-width: 420px) {

    .certified-members {
        flex-direction: column;
        width: 100%;
    }
    .certified-member-card {
        width: 100%;
        margin-bottom: 10px;
    }
}
