.footer-container {
    background-color: #eef3ec;
}

.footer-info-detail {
    display: flex;
    justify-content: space-between;
}

.footer-text {
    width: 33%;
}

.footer-items-container {
    margin-bottom: 50px;
}

.footer-items {
    margin-bottom: 30px;
}

.footer-items h6 {
    margin-bottom: 10px;
}

.footer-items li, p {
    font-size: 0.9rem;
}

.footer-icons {
    margin-bottom: 30px;
    display: flex;
}

.footer-icon a {
    color: #1f3f32;
    transition: .5s ease;
}

.footer-icon a:hover {
    color: #2E6A4F
}

.footer-copyright {
    border-top: 1px solid rgba(51, 51, 51, 0.3);
    padding-top: 15px;
}



.form-container {
    width: 50%;
}

.form-header {
    margin-bottom: 30px;
}

.form-header h2 {
    margin-bottom: 10px;
}

form {
    display: flex;
    flex-direction: column; 
}

.formStyle {
    margin-bottom: 10px;
    padding: 7px 15px;
    border: 1px solid rgba(51, 51, 51, 0.3);
    font-size: 0.9rem;
    font-family:'Montserrat', sans-serif;
}

textarea {
    height: 120px;
}

.submit-btn {
    text-align: center;
    padding: 10px;
    background-color: #1f3f32;
    color: #fff;
    border-radius: 10px;
}

.info-message {
    margin-top: 15px;
    background-color: #CFF4FC;
    padding: 20px;
    
}


.webdesign {
    background-color: #1f3f32;
    color: #fff;
    padding-top: 20px;
    padding-bottom: 20px;
}

.webdesign a {
    color: #fff;
    text-decoration: underline;
}

.disabled-input {
    background-color: #f1f1f1; /* Set the desired background color */
    color: #777; /* Optionally, you can also change the text color for better contrast */
}

.disabled-btn:hover {
    cursor: default
}



@media screen and (max-width: 768px) {
    .footer-info-detail {
        flex-direction: column-reverse;
    }

    .footer-text {
        width: 100%;
        margin-top: 50px;
    }

    .form-container {
        width: 100%;
    }
}