.navbar-container {
    position: relative;
}

.main-navbar {
    width: 100%;
}

.main-navbar-header {
    align-items: center;
    display: flex;
    margin: 0 auto;
    padding: 20px 90px 20px 90px;
    justify-content: space-between;
    
}

.main-navbar-title, .scroll-navbar-title {
    align-items: center;
    display: flex;
}

.logo-img {
    width: 100%;
}

.main-navbar-logo-container {
    height: 120px;
    margin-right: 10px;
    width: 120px;
}

.main-navbar-name {
    font-size: 1.5rem;
    font-weight: 700;
    line-height: 1;
    margin-bottom: 5px;
}

.contact-info-container {
    align-items: flex-end;
    background-color: #fff;
    color: #333;
    display: flex;
    flex-direction: column;
    font-size: 0.8rem;
    justify-content: space-between;
    width: 400px; 
}

.contact-info { 
    align-items: center;
    display: flex;
}

.contact-info-detail {
    color:#1f3f32;
    margin-right: 10px;
    font-weight: bold;
}

.contact-info-icon {
    color: #2E6A4F;
}

.main-navigation-bar {
    align-items: center;
    background-color: #1f3f32;
    border-bottom: 5px solid #41916c;
    color: #fff;
    display: flex;
    font-size: 0.8rem;
    font-weight: 500;
    justify-content: space-between;
    padding: 0 90px 0 90px;
    width: 100%;    
}

.navigation-menus {
    align-items: center;
    display: flex;
}

.navigation-menu {
    display: flex;
    position: relative;
}

.navigation-menu li {
    padding: 10px 10px;
    font-size: 0.9rem;
    transition: 0.3s;
}

.navigation-menu li.donate {
    background-color: #2E6A4F;
    border-radius: 5px;
    color: #fff;
}

.navigation-menu li.donate:hover {
    background-color: #6dc7a3;
    color: #fff;
}

.navigation-menu .active {
    color: #2E6A4F;
    font-weight: bold;
}

.navigation-menus .navigation-menu a, .dropdown {
    color: #fff;
    font-weight: normal;
    padding: 5px 0;
}

.navigation-menus .navigation-menu a:hover, .dropdown:hover {
    background-color: #41916c
}

.navigation-menus .navigation-menu .active-link {
    background-color: #41916c;
    color:#fff;
    transition: 0.5s;;
}

.socials-icons {
    align-items: center;
    border-left: 0px solid #fff;
    display: flex;
    height:20px;
    
    padding-left: 10px;
}

.socials-icons .icon a {
    color: #fff;
}

.icon {
    align-items: center;
    display: flex;
    color:#2E6A4F;
    line-height: 1;
    padding: 0 5px;
}

.icon a:hover {
    color: #81dbb1;
}

.search-container {
    align-items: center;
    display: flex;
}

.search-container *,
.search-container *:after,
.search-container *:before {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  -ms-box-sizing: border-box;
  box-sizing: border-box;
}

/* search bar focus */
.search-container *:focus {
  background: #fbfbfb;
  color: #333333;
  outline: 0;
}

/* search bar container */
.search-container {
  display: table;
  position: relative;
  width: 30px;
}

/* search icon button */
.search-icon-btn {
    align-items: center;
    background-color: #fff;
    border: 0px solid #fff;
    color: #1f3f32;
    display: flex;
    font-size: 24px;
    height: 30px;
    justify-content: center;  
    position: relative;
    width: 30px;
    z-index: 2;
}

/* search bar input container */
.search-input {
    bottom: 0.5px;
    position: absolute;
    right: 0;
    z-index: 1;
}

/* search bar input */
.search-input input.search-bar {
    border: 1px solid #fff;
    height: 30px;
    padding: 0px;
    width: 30px;
}

.search-input input.search-bar:disabled {
    background-color: #fff;
}

.search-input input.search-bar:focus {
    padding-left: 10px;
    padding-right: 60px;
    width: 250px;
}

/* transition effect */
.search-input input.search-bar,
.search-icon-btn {
    -webkit-transition: all 0.5s;
    -moz-transition: all 0.5s;
    -ms-transition: all 0.5s;
    -o-transition: all 0.5s;
    transition: all 0.5s;
}

/* hover on search bar container */
.search-container:hover > .search-input input.search-bar {
    padding-right: 60px;
    padding-left: 10px;
    width: 250px;
}




/*** CONDITIONAL NAVBAR ***/


.scroll-navbar {
    position: fixed;
    top: 0;
    height: 120px;
    width: 100%;
    z-index: 100;
}

.scroll-navbar-header {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0px 90px;
    border-bottom: 5px solid #2E6A4F;
    height:8vh;
    background-color: #fff;
}


.scroll-navbar-logo-container {
    width: 38px;
    height: 38px;
    margin-right: 10px;
}

.scroll-navbar-title-text {
    font-size: 0.7rem;
    line-height: 1;
    font-weight: bold;
}

.mobile-screen-socials-icons {
    display: flex;
    text-align: left;
    width: 100%;
    padding: 0px 0vw;
    margin-top: 20px;
}

.mobile-screen-icon {
    margin-right: 7px;
    color:#2E6A4F;
}

.hamburger, .close-hamburger, .hide-container {
    display: none;
}


.dropdown {
    position: relative;
}

.triangle-up {
	width: 0;
	height: 0;
	border-left: 15px solid transparent;
	border-right: 15px solid transparent;
	border-bottom: 20px solid #fff;
    margin-left: 20px;
}

.dropdown-content {
    display: flex;
    flex-direction: column;
    position: absolute;
    top: 40px;
    border-radius: 10px;
}

.navigation-menu .dropdown .dropdown-link {
    padding: 15px 10px 15px 20px;
    display: block;
    text-decoration: none;
    color: #41916c;
    font-weight: 600;
    font-size: 0.9rem;
    background-color: #fff;
    width: 200px;
    border-bottom: 1px solid rgba(51, 51, 51, 0.1);
}

  .navigation-menu .dropdown .dropdown-link:hover {
    color: #1f3f32;
    background-color: #fff;
}






.scroll-navbar-header .navigation-menu .dropdown {
    padding: 0;
}

.scroll-navbar-header .navigation-menu .dropdown .triangle-up {
    border-bottom: 20px solid #41916c;
}

.scroll-navbar-header .navigation-menu .dropdown .dropdown-content {
    top: 30px;
}

.scroll-navbar-header .navigation-menu .dropdown li {
    color: #2E6A4F;
    background-color: #fff;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.scroll-navbar-header .navigation-menu .dropdown .dropdown-link {
    color: #fff;
    background-color: #41916c;
}

.scroll-navbar-header .navigation-menu .dropdown .dropdown-link:hover {
    background-color: #46a078;
}

.dropdown-icon {
    display: none
}


.contact-link:hover {
    cursor: pointer;
}


@media screen and (max-width: 992px) {  

    .scroll-navbar-header {
        width: 100%;
        padding: 1rem 4vw;
        height: 8vh;
    }

    .navigation-menu li.donate {
        text-align: center;
    }
    
    .hide-container {
        display: block
    }

    .mobile-screen-search-container {
        display: flex;
        border: 1px solid rgba(31, 63, 50, 0.3);
        width: 100%;
        padding: 0;
    }

    .mobile-screen-search-container .search-icon-btn {
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: #1f3f32;
        color: #fff;
        height: auto;
        width: 40px;
    }

    .mobile-screen-search-container .search-input {
        position: relative;
        margin: 0;
        margin-left: 10px;
        padding: 5px 0;
    }

    .mobile-screen-search-container .search-input input.search-bar {
        margin: 0;
        width: 100%;
    }

    .navigation-menu {
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-end;
        width: 100%;
        height: 92vh;
        background: #fff /*rgba(255, 255, 255, 1) #eff2f4*/;
        position: absolute;
        top: 8vh;
        right: -100%;
        z-index: 10;
        transition: 0.5s;
        padding: 10px 4vw 0;
    }

    .navigation-menu li {
        
        width: 92vw;
        padding: 12px 0px;
        color: #2E6A4F;
        border-bottom: 1px solid rgba(0, 0, 0, 0.15);
        text-align: left;
        
    }

    .navigation-menu a li {
        font-size: 0.9rem;
        font-weight: 600;
    }

    .navigation-menu {
        top: 8vh;
    }

    .navigation-menu.active {
        right: 0;
        top: 8vh
    }

    li.contact {
        border: 0;
    }

    .hamburger, .close-hamburger {
        display: initial;
        z-index: 11;
    }


    .navigation-menu .dropdown-content {
        display: flex;
        flex-direction: column;
        position: initial;
    }

    .navigation-menu .dropdown-content .dropdown-link {
        width: 100%;
    }
    
    .triangle-up {
        display: none;
    }

    .dropdown-icon {
        display: block
    }


    .scroll-navbar-header .navigation-menu .dropdown {
        background-color: #fff;
        border: 0;
    }

    .scroll-navbar-header .navigation-menu .dropdown .dropdown-content {
        border: 0;
    }

    .scroll-navbar-header .navigation-menu .dropdown .dropdown-link {
        color: #2E6A4F;
        background-color: #fff;
        border-left: 0;
    }

    .scroll-navbar-header .navigation-menu .dropdown .dropdown-link:hover {
        color: #41916c;
        background-color: #fff;
    }
}


@media screen and (max-width: 768px) {
    .scroll-navbar-header {
        height: 10vh;
    }

    .scroll-navbar-logo-container {
        width: 45px;
        height: 45px;
    }

    .navigation-menu {
        top: 10vh;
    }

    .navigation-menu.active {
        right: 0;
        top: 10vh
    }
}


@media screen and (max-width: 992px) {
    .main-navbar {
        display: none;
    }
}